// eslint-disable-next-line react-hooks/exhaustive-deps
import { ActiveLogo, InactiveLogo } from 'app/components/ConnectorLogo/ConnectorLogo';
import axios from 'axios';
import React, { useEffect, useState, MouseEvent } from 'react'
import { Modal, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getAuth, useAuth } from "../../modules/auth";
import Token from '../../modules/hooks/Token/Token';
import ValideteError from '../../modules/hooks/ValideteError/ValideteError';

interface integration {
  id: number,
  title: string
  connected: boolean
}

const Integration = () => {
  const [selectedDrink, setSelectedDrink] = useState(false);
  const [hasServiceConnected, setHasServiceConnected] = useState<boolean>(false)
  const [select, setSelect] = useState<string>("")
  const [list, setList] = useState<integration[]>([])
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selected, setSelectd] = useState(null)
  const [remove, setRemove] = useState(0)
  const [success, setSuccess] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = (e: MouseEvent) => {
    e.preventDefault()
    setShow(true);
  }

  const auth = Token()
  const get_auth = getAuth()

  const { logout, saveAuth } = useAuth()

  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelect(event.target.value)
  };

  const GetList = async () => {
    try {
      setLoading(true)
      const res = await axios.post('/colab/list-connectors-available', { "token": auth })
      setList(res.data.data)
      const listResponse = res.data.data
      setHasServiceConnected(listResponse.some(item => item.connected))
      setLoading(false)
    } catch (e) {
      if (ValideteError(e)) {
        logout()
      }
    }
  }

  useEffect(() => {
    GetList()
    if (remove === 200) {
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 8000)
    }
    // eslint-disable-next-line
  }, [remove])

  const Remove = async (id: number) => {
    handleClose()
    try {
      setLoadingRemove(true)
      const res = await axios.post('/colab/delete-connector', {
        "connectorId": id,
        "token": auth,
        "data": true
      })
      setLoadingRemove(false)
      setRemove(res.status)
      saveAuth({
        ...get_auth,
        hasConnector: false
      })
    } catch (e) {
      if (ValideteError(e)) {
        logout()
      }
    }
  }

  const Toogle = i => {
    if (selected === i || hasServiceConnected) {
      setSelectd(null)
      setSelectedDrink(null);
    } else {
      setSelectd(i)
      setSelectedDrink(true);
    }
  }

  function renderSwitchTitle(data) {
    switch (data) {
      case 'loja-integrada': return 'Loja Integrada';
      case 'nuvem-shop': return 'Nuvemshop';
      case 'e-com-plus': return 'E-Com Plus';

      default: return data;
    }
  }

  return (
    <>
      <h1>Conectores</h1>
      <p className='text-gray-500'>Conecte suas fontes de dados para criar desafios e acompanhar seus resultados.</p>
      {success && <div className='alert alert-success d-flex flex-column flex-sm-row align-items-center p-5 mb-10 border-dashed border-success border-1' role="alert">
        <span className="svg-icon svg-icon-success svg-icon-2hx pe-sm-5">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
            <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
          </svg>
        </span>
        <div className=''>
          <h3>Integração revogada com sucesso</h3>
          <p className='mb-0'>Para fazer uma nova integração, escolha a plataforma a seguir.</p>
        </div>
      </div>}
      <div className='dividers my-5'></div>
      <form className='py-5 px-md-20'>
        {loading ?
          <div className='d-flex justify-content-center'>
            <div className="spinner-grow text-primary" role="status" />
          </div> : <>
            {list.map((data, i) => {
              return (
                <div key={i}>
                  {data.connected === true ?
                    <>
                      <input type="radio" value={data.id} name="api" id={`${data.id}`} className='d-none' onChange={radioHandler} />
                      <div className="alert alert-success d-flex flex-column flex-sm-row align-items-center p-5 mb-10 border-dashed border-success border-1">
                        <span className="svg-icon svg-icon-5hx svg-icon-sm-3hx svg-icon-success me-4">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"></path>
                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="currentColor"></path>
                          </svg>
                        </span>
                        <div className="d-flex flex-column align-items-center align-items-sm-baseline justify-content-center justify-content-sm-start">
                          <h2 className={`mb-1 text-success ${data.title === "vtex" ? 'text-uppercase' : 'text-capitalize'}`}>{renderSwitchTitle(data.title)}</h2>
                          <p className={`mb-0`}>
                            Conecte seu e-commerce da <span className={`${data.title === "vtex" ? 'text-uppercase' : 'text-capitalize'}`}>{renderSwitchTitle(data.title)}</span> com a BrandLovrs
                          </p>
                          <div className='d-flex mt-5'>
                            <Link to={`edit/${data.id}`}>
                              <span className='btn btn-success me-5 '>Editar</span>
                            </Link>
                            <button className='btn btn-danger' onClick={(e) => handleShow(e)}>
                              Excluir
                            </button>
                          </div>
                        </div>
                      </div>
                      {get_auth.challenge > 0 ?
                        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                          <Modal.Header closeButton>
                            <h2>Excluir Integração</h2>
                          </Modal.Header>
                          <Modal.Body>
                            Ao excluir esta integração, você não conseguirá mais acessar os dados do seu e-commerce na nossa plataforma. Tem certeza que quer excluir?
                          </Modal.Body>
                          <Modal.Footer>
                            <div className='d-flex justify-content-end'>
                              <button className='btn btn-danger me-5' onClick={() => Remove(data.id)}>Excluir
                                {loadingRemove && <Spinner animation="grow" variant="light" size="sm" className='pe-2' />}
                              </button>
                              <button className='btn btn-primary' onClick={handleClose}>Cancelar</button>
                            </div>
                          </Modal.Footer>
                        </Modal> :
                        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                          <Modal.Header closeButton>
                            <h2>Erro ao Excluir Integração</h2>
                          </Modal.Header>
                          <Modal.Body>
                            <p>Por questões de segurança, não é permitido excluir a integração enquanto estiver com Desafios ativos.</p>
                            <p>Por favor, finalize todos os seus Desafios antes de prosseguir.</p>
                          </Modal.Body>
                          <Modal.Footer>
                            <div className='d-flex justify-content-end'>
                              <button className='btn btn-primary' onClick={handleClose}>Fechar</button>
                            </div>
                          </Modal.Footer>
                        </Modal>}

                    </> : <>
                      <input type="radio" value={data.id} name="api" id={`${data.id}`} className='d-none' onChange={radioHandler} onClick={() => Toogle(i)} />
                      <label className={`alert alert-dismissible ${selected === i ? "bg-ligth-blue border-dashed-ligth-blue" : "bg-gray-200 border-dashed"}  d-flex align-items-center flex-column flex-sm-row p-5 mb-10 border-1 cursor-pointer`} htmlFor={`${data.id}`}>
                        <div className='pe-5'>
                          {selected === i ? ActiveLogo(data.title) : InactiveLogo(data.title)}
                        </div>
                        <div>
                          <h2 className={`${data.title === "vtex" ? 'text-uppercase' : 'text-capitalize'}`}><span>{renderSwitchTitle(data.title)}</span> </h2>
                          <p className={`text-gray-500 mb-0`}>
                            Conecte seu e-commerce da <span className={`${data.title === "vtex" ? 'text-uppercase' : 'text-capitalize'}`}>{renderSwitchTitle(data.title)} </span>  com a BrandLovrs
                          </p>
                        </div>
                      </label>
                    </>}
                </div>
              )
            })}
          </>}
      </form>
      {selectedDrink && !hasServiceConnected ? (
        <>
          <div className='dividers my-5'></div>
          <div className='d-flex justify-content-end'>
            <Link to={`/dashboard/integration/${select}`}>
              <button
                disabled={selectedDrink ? false : true}
                className={`btn ${selectedDrink ? 'btn-primary' : 'btn-secondary'}`}>
                Avançar
              </button>
            </Link>
          </div>
        </>
      ) : ""}
    </>
  )
}

export default Integration

