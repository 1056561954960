import { useState } from 'react';
import { DateFormat } from '../../../../modules/hooks/DateFormat/DateFormat';
import { IChartRequests } from '../../index';

import { InterestsList } from '../InterestsList';
import { TagsList } from '../TagsList';

import styles from './styles.module.scss';

type ChartRequestData = {
  chart: IChartRequests;
  handleChangeStatusRequest: any;
  handleGetAmbassadorTagList: Function;
  isLoadingButton: number;
}

export const ChartRequestCard = ({ chart, handleChangeStatusRequest, isLoadingButton, handleGetAmbassadorTagList }: ChartRequestData) => {
  const { ambassadorId, createdAt, email, name, interestsName, tagsName, approvalStatus } = chart; // followers
  const [isApproving, setIsApproving] = useState(false);

  return (
    <>
      <div className="col-sm-6 col-lg-4 col-xxl-3 mb-5">
        <div className={`h-100 rounded-3 p-8 ${styles.chartContainer} ${(approvalStatus === true) && styles.approvedStatus} ${(approvalStatus === false) && styles.declinedStatus}`}>
          <div className='row'>
            <p className='text-end text-gray-500 fs-6'>{DateFormat(createdAt)}</p>
            <h2 className="text-center pt-1" >{name.split(' ').length > 1 ? `${name.split(' ')[0]} ${name.split(' ')[name.split(' ').length - 1]}` : name}</h2>
            <p className="text-center text-gray-500 fst-italic fs-8">
              {email ?? "Não conectado"}
            </p>
          </div>

          <InterestsList interests={interestsName} />

          <TagsList tags={tagsName} ambassadorId={ambassadorId} canCreateTag={approvalStatus} handleGetAmbassadorTagList={handleGetAmbassadorTagList} />

          <div className="row p-0">
            <div className="col-12 mb-5 mb-xl-0 col-xl-6 d-flex justify-content-center align-content-center">
              <button
                className={`btn btn-sm ${(approvalStatus === true) ? 'btn-outline-success' : 'btn-success'}`} disabled={(approvalStatus === true)}
                onClick={() => {
                  handleChangeStatusRequest(true, chart.id);
                  setIsApproving(true);
                }}
              >
                {(approvalStatus === true) ? <span className='approvedTxt'>Aprovado</span> : 'Aprovar'}

                {
                  (approvalStatus === true || !isApproving)
                    ?
                    <></>
                    :
                    isLoadingButton === chart.id
                      ?
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      :
                      <></>
                }
              </button>
            </div>

            <div className="col-12 mb-5 mb-xl-0 col-xl-6 d-flex justify-content-center align-content-center">
              <button
                className={`btn btn-sm ${(approvalStatus === false) ? 'btn-outline-danger' : 'btn-danger'}`} disabled={(approvalStatus === false)}
                onClick={() => {
                  handleChangeStatusRequest(false, chart.id);
                  setIsApproving(false);
                }}
              >
                {(approvalStatus === false) ? <span className='declinedTxt'>Reprovado</span> : 'Reprovar'}

                {
                  (approvalStatus === false || isApproving)
                    ?
                    <></>
                    :
                    isLoadingButton === chart.id
                      ?
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      :
                      <></>
                }
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
