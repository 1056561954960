import axios from 'axios'
import { AuthModel, UserModel } from './_models'

export const LOGIN_URL = 'auth/login'
export const REGISTER_URL = 'create-brand'
export const REQUEST_PASSWORD_URL = 'auth/recover-password'
export const GET_USER_BY_ACCESSTOKEN_URL = `verify_token`
export const RESET_PASSWORD_URL = 'auth/confirm-password'

// Server should return 

export function login(email: string, password: string) {
    return axios.post<AuthModel>(LOGIN_URL, {
        email,
        password,
        product: 'colab',
        context: 'brand-user'
    })

}

// Server should return AuthModel
export function register(
    email: string,
    name: string,
    companyName: string,
    siteAddress: string,
    password: string,
    passwordConfirmation: string,
    acceptTerms: boolean
) {
    return axios.post(REGISTER_URL, {
        name,
        email,
        password,
        companyName,
        siteAddress,
        passwordConfirmation,
        acceptTerms,
        'service': 'colab',
        'context': 'brand'
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
        email,
        'service': 'colab',
        'context': 'brand'
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function resetPassword(email: string, token: string, password: string) {
    return axios.post<{ result: boolean }>(RESET_PASSWORD_URL, {
        email,
        token,
        newPassword: password,
        'service': 'colab',
        'context': 'brand'
    })
}


export function getUserByToken(token: string) {
    return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
        api_token: token,
    })
}

export function getConnectores(token: string) {
    return axios.post<any>(`/colab/list-connectors-available`, {
        token: token,
    })

}

export async function getAmbassadors(data: { token: string, brandId: string }) {
  return axios.post(`colab/influencer-panel`, {
      "brandToken": data.token,
      "brandId": data.brandId,
      "filter": ["pending"],
      "order": "desc",
      "page": 1,
      "pageSize": 8
  })
}


export async function getListChallenge(token: string) {
    return axios.post(`/colab/list-challenges`, {
        'token': token,
        'page': 1,
        'pageSize': 50,
        'order': 'desc'
    })
}

export function getUser(id: string) {
    return axios.post<any>(`/profile/show`, {
        id: id
    })
}

export function getBalance(id: string) {
    return axios.post<any>(`/colab/wallet/balance`, {
        token: id
    })
}



