import React, {Suspense, useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AUTH_LOCAL_STORAGE_KEY, AuthInit} from './modules/auth'
import './assets/scss/_base.scss'
import axios from 'axios'
import jwt_decode from "jwt-decode";

const usePageViews = () => {
  const location = useLocation();
  useEffect(() => {
    window.analytics.page(location.pathname)
  },[location]);
}
var queryparams = window.location.search.split('?')[1]
var params = queryparams?.split('&')

var pair = null
var data = []
params?.forEach(function(d) {
  pair = d.split('=')
  data.push({ key: pair[0], value: pair[1] })
  if (pair[0] === 'tokenNewFront') {
    const brand: any = jwt_decode(pair[1]);
    const newTokenLocalStorage = {
      api_token: pair[1],
      brandId: brand.permissions[0].brandId
    }
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(newTokenLocalStorage))
  }
})
const App = () => {
  usePageViews();
  const [force, setForce]=React.useState('teste')
  const token = JSON.parse(localStorage.getItem('kt-auth-react-v'))
  React.useLayoutEffect(()=>{
    var queryparams = window.location.search.split('?')[1]
    var params = queryparams?.split('&')
    
    var pair = null
    var data = []
    params?.forEach(function(d) {
      pair = d.split('=')
      data.push({ key: pair[0], value: pair[1] })
      if (pair[0] === 'tokenNewFront') {
        axios.post('catalyst/verify-token', { accessToken: token.api_token }).then(res=>{
          localStorage.setItem('accessPayload', JSON.stringify(res.data))
        }).then(()=>{
          setForce('us guri')
        })
        }
      }
    )
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {force === 'teste' ?  
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
        :
        <div>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </div>
      }
    </Suspense>  
  )
}

export {App}
