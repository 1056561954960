import { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import axios from "axios";
import MultiSelect from "multiselect-react-dropdown";

import Token from "../../modules/hooks/Token/Token";
import ValideteError from "../../modules/hooks/ValideteError/ValideteError";
import { useAuth } from "../../modules/auth";

import { ChartRequestCard, CommunityList } from "./components";
import Paginative from "../Paginative/Paginative";
import { FaCopy } from "react-icons/fa";
import { toastSuccess } from "app/components/ModalSwal/ModalSwal";

export interface IChartRequests {
  createdAt: string;
  followers: string;
  id: number;
  ambassadorId: number;
  interestsName: string[];
  tagsName: any;
  name: string;
  email: string;
  username: string;
  approvalStatus?: boolean | undefined;
  phone: string;
  state: string;
  socialMedia: any;
}

type OrderFilter = "asc" | "desc";
type ListFilter = "all" | "approved" | "declined" | "pending" | "name" | "tags";

interface ChartsFilter {
  order: OrderFilter;
  filter: any;
  page: number;
  tagsNames?: any[];
  ambassadorName?: string;
  searchName?: string;
}

interface TagData {
  id: number;
  name: string;
}

export const ChartRequests = () => {
  const [chartRequests, setChartRequests] = useState<IChartRequests[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [viewType, setViewType] = useState("list");
  const [copied, setCopied] = useState(false);

  const [totalRequestsByFilter, setTotalRequestsByFilter] = useState(0);
  const [chartsFilter, setChartsFilter] = useState<ChartsFilter>({
    order: "desc",
    filter: "all",
    page: currentPage,
    tagsNames: [],
    searchName: "",
  });
  const [brandTags, setBrandTags] = useState<TagData[]>([]);
  const [newRequest, setNewRequest] = useState<number>();

  const limit = 8;
  const auth = Token();
  const { logout } = useAuth();

  async function listChartRequests() {
    try {
      setIsLoading(true);
      const payload: any = localStorage.getItem("kt-auth-react-v");
      let parsedPayload;
      if (payload) parsedPayload = JSON.parse(payload);

      const res = await axios.post("colab/influencer-panel", {
        brandToken: parsedPayload.api_token,
        brandId: parsedPayload.brandId,

        filter: [chartsFilter.filter, "tags", "name"],
        order: chartsFilter.order,
        page: currentPage,
        pageSize: limit,

        ambassadorName: chartsFilter?.searchName,
        tagsNames: chartsFilter?.tagsNames,
      });

      setTotalRequestsByFilter(res.data.totalRequestsByFilter);
      setChartRequests(res.data.chartsRequest);
      setNewRequest(res.data.totalNewRequests);
      handleListBrandTags();
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function handleListBrandTags() {
    const payload: any = localStorage.getItem("kt-auth-react-v");

    let parsedPayload;
    if (payload) parsedPayload = JSON.parse(payload);

    const response = await axios.post("colab/list-brand-ambassador-tags", {
      accessToken: parsedPayload.api_token,
      brandId: parsedPayload.brandId,
    });

    setBrandTags(response.data);
  }

  useEffect(() => {
    listChartRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartsFilter, refresh, currentPage]);

  // function handleOrderFilter(filterValue: OrderFilter) {
  //   window.analytics.track('Influencer Listed Filter Changed', {
  //     order: filterValue,
  //   });

  //   setChartsFilter({ ...chartsFilter, order: filterValue });
  //   setIsLoading(true);
  // }

  // function handlePageChange(page: number) {
  //   setChartsFilter({ ...chartsFilter, page: page })
  // }

  function handleListFilter(filterValue: ListFilter) {
    window.analytics.track("Influencer Listed Filter Changed", {
      filterLabel: filterValue,
    });

    setChartsFilter({ ...chartsFilter, filter: filterValue });
    setIsLoading(true);
  }

  async function handleGetAmbassadorTagList() {
    try {
      setIsLoading(false);

      const payload: any = localStorage.getItem("kt-auth-react-v");
      let parsedPayload;
      if (payload) parsedPayload = JSON.parse(payload);

      const res = await axios.post("colab/influencer-panel", {
        brandToken: parsedPayload.api_token,
        brandId: parsedPayload.brandId,

        filter: [chartsFilter.filter],
        order: chartsFilter.order,
        page: currentPage,
        pageSize: limit,
      });

      setChartRequests(res.data.chartsRequest);
    } catch (e) {
      if (ValideteError(e)) {
        logout();
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function handleChangeStatusRequest(status: boolean, requestId: number) {
    try {
      setIsLoadingButton(requestId);

      const response = await axios.post("colab/influencer-apply-result", {
        brandToken: auth,
        requestId: requestId,
        approvalStatus: status,
      });

      if (response.status === 200) {
        status &&
          window.analytics.track("Influencer Approved", {
            influencerId: requestId,
          });

        !status &&
          window.analytics.track("Influencer Declined", {
            influencerId: requestId,
          });

        setRefresh(refresh + 1);
      }
      setIsLoadingButton(0);
    } catch (e) {
      if (e.response.data.error === "User not found") {
        logout();
      }
    }
  }

  function handleAddFilterTags(selectedList, selectedItem) {
    setChartsFilter({
      ...chartsFilter,
      tagsNames: [...chartsFilter.tagsNames, selectedItem.name],
    });
  }

  function handleRemoveFilterTags(selectedList, removedItem) {
    setChartsFilter({
      ...chartsFilter,
      tagsNames: chartsFilter.tagsNames.filter(function (tag) {
        return tag !== removedItem.name;
      }),
    });
  }

  function handleChangeCommunityView(viewType: string) {
    setIsLoading(true);
    setViewType(viewType);

    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }

  const handleCopy = () => {
    const payload: any = localStorage.getItem("kt-auth-react-v");
    let parsedPayload;
    if (payload) parsedPayload = JSON.parse(payload);
    navigator.clipboard.writeText(
      `https://webapp.brandlovrs.com/auth/register/brand/${parsedPayload.brandId}`
    );
    setCopied(true);
    toastSuccess(
      "",
      "Link copiado com sucesso.",
    );
  };

  return (
    <>
      <h1 className="mb-8">Minha comunidade</h1>
      <div className="container row justify-content-md-between mb-10">
        <div className="d-flex align-items-md-center justify-content-between">
          <div className="d-flex align-items-md-center justify-content-start">
            <div className="d-flex flex-column flex-md-row align-items-md-center min-w-250px pb-5 pb-md-0">
              <Form.Control
                className="me-3 search-icon"
                onInput={(e) =>
                  setChartsFilter({
                    ...chartsFilter,
                    searchName: e.currentTarget.value,
                  })
                }
                placeholder="Pesquisar por nome"
              />
            </div>
          </div>

          <div className="d-flex align-items-md-center justify-content-end">
            {/*
            // FILTRO DE ORDENACAO REMOVIDO NA SPRINT 31, POREM PODE RETORNAR FUTURAMENTE

            <div className='d-flex flex-column flex-md-row align-items-md-center pb-5 pb-md-0'>
              <span className='w-100'>
                <label htmlFor="select" className='fw-bold d-block'> Ordenar por:</label>
              </span>

              <select className="form-select" id="select" onChange={(e) => { handleOrderFilter(e.target.value as OrderFilter) }}>
                <option value="desc">Mais Recentes</option>
                <option value="asc">Mais Antigas</option>
              </select>
            </div>
          */}

            <div className="d-flex align-items-md-center pb-5 pb-md-0 mx-5">
              <span className="">
                <label htmlFor="select" className="fw-bold d-block me-3">
                  {" "}
                  Listar:
                </label>
              </span>

              <select
                className="form-select"
                onChange={(e) => {
                  handleListFilter(e.target.value as ListFilter);
                }}
              >
                <option value="pending">Novas Solicitações</option>
                <option value="approved">Aprovados</option>
                <option value="declined">Reprovados</option>
                <option value="all" selected>
                  Mostrar Todos
                </option>
              </select>
            </div>

            <div className="d-flex align-items-md-center pb-5 pb-md-0 mx-5">
              <span className="">
                <label htmlFor="select" className="fw-bold d-block me-3">
                  {" "}
                  Tags:
                </label>
              </span>

              {/* { onChange={(e) => { handleListFilter(e.target.value as ListFilter) }} } */}
              <MultiSelect
                options={brandTags}
                displayValue="name"
                isObject
                showCheckbox
                placeholder="Pesquise e filtre por tags"
                emptyRecordMsg="Nenhuma opção disponível"
                hidePlaceholder={false}
                showArrow
                onSelect={handleAddFilterTags}
                onRemove={handleRemoveFilterTags}
                style={{
                  chips: {
                    display: "none",
                  },
                  searchBox: {
                    border: "0px solid #ccc",
                    borderRadius: "0px",
                    minHeight: "0px",
                    padding: "0px",
                    position: "relative",
                  },
                  inputField: {
                    display: "block",
                    width: "100%",
                    padding: "0.75rem 3rem 0.75rem 1rem",
                    MozPaddingStart: "calc(1rem - 3px)",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                    lineHeight: "1.5",
                    color: "#5e6278",
                    backgroundColor: "#ffffff",
                    backgroundImage:
                      "url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237e8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right 1rem center",
                    backgroundSize: "16px 12px",
                    border: "1px solid #e4e6ef",
                    borderRadius: "0.475rem",
                    boxShadow: "inset 0 1px 2px rgb(0 0 0 / 8%)",
                    transition:
                      "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                    appearance: "none",
                  },
                }}
              />

              <div className="ps-4">
                {viewType === "list" ? (
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary"
                    onClick={() => handleChangeCommunityView("grid")}
                  >
                    <i className="bi fs-1 pe-0 py-1 bi-grid-3x3-gap"></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary"
                    onClick={() => handleChangeCommunityView("list")}
                  >
                    <i className="bi fs-1 pe-0 py-1 bi-list"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <h1>Embaixadores</h1>
          {!isLoading && (
            <>
              {" "}
              {newRequest !== 0 && (
                <>
                  <span
                    className="badge bg-blue rounded-1"
                    style={{ border: "2px" }}
                  >
                    {newRequest}
                  </span>
                  <p className="text-gray-400 fw-bold fs-5 d-inline mt-5 ms-2">
                    Novas solicitações
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {!isLoading ? (
        <>
          <div className="row">
            {viewType === "list" ? (
              <CommunityList
                listData={chartRequests}
                handleChangeStatusRequest={handleChangeStatusRequest}
                handleGetAmbassadorTagList={handleGetAmbassadorTagList}
                isLoadingButton={isLoadingButton}
              />
            ) : (
              chartRequests.map((chart) => (
                <ChartRequestCard
                  key={chart.id}
                  chart={chart}
                  handleChangeStatusRequest={handleChangeStatusRequest}
                  handleGetAmbassadorTagList={handleGetAmbassadorTagList}
                  isLoadingButton={isLoadingButton}
                />
              ))
            )}
          </div>

          <Paginative
            setActivePage={setCurrentPage}
            text={true}
            activePage={currentPage}
            limit={limit}
            totalRequests={totalRequestsByFilter}
          />
        </>
      ) : (
        <div className="d-flex justify-content-center p-20 h-100">
          <Spinner animation="grow" variant="primary" />
        </div>
      )}
      {!isLoading && (
        <>
          <div
            className={`alert alert-success ${
              copied
                ? "border-success border-dashed"
                : "border-dashed-ligth-blue bg-ligth-blue"
            } d-flex align-items-center mt-5 border-1`}
          >
            <div
              className="px-5 d-flex align-items-center cursor-pointer"
              onClick={handleCopy}
            >
              <h4 className="m-0 px-2">Formulário</h4>
              <FaCopy style={{ height: "24px", color: "#000" }} />
            </div>

            <div className="px-20 d-flex align-items-center">
              <p className="m-0">
                Insira no seu site e chame a sua comunidade para engajar nos
                seus Desafios!
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
