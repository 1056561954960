/* eslint-disable react-hooks/exhaustive-deps */
import { FaCheck, FaTimes } from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';

import { Table } from 'app/components/Table';
import { ActionButton } from 'app/components/Buttons';
import { Check, XMark } from 'app/components/Icons';

import { SocialMediaList } from '../SocialMediaList';
import { AmbassadorResultDashboard } from '../AmbassadorResultDashboard';
import { TagsList } from '../TagsList';
import { InterestsList } from '../InterestsList';

import { IChartRequests } from '../../index';

import styles from './styles.module.scss';
import { Dropdown } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';

type CommunityListData = {
  listData: IChartRequests[];
  handleChangeStatusRequest: any;
  handleGetAmbassadorTagList: Function;
  isLoadingButton: number;
}

export const CommunityList = ({ listData, handleChangeStatusRequest, isLoadingButton, handleGetAmbassadorTagList }: CommunityListData) => {


  return (
    <section className='mb-10'>
      <div className="scroll-view">
        <Table
          headers={[
            { value: 'Nome' },
            { value: 'Redes Sociais', isSortable: false },
            { value: 'Instagram', isSortable: false },
            { value: 'Tags e Interesses', isSortable: false },
            { value: 'Resultados', isSortable: false },
            { value: 'Status', isSortable: false },
            { value: '' }
          ]}
          rows={
            Object.keys(listData).length === 0 ? [] :
              listData.map((ambassador) => ({
                key: ambassador.ambassadorId,
                values: [
                  ambassador.name,
                  <SocialMediaList medias={ambassador.socialMedia} />,
                  !ambassador.followers ? <span className='fst-italic' style={{ fontSize: '10px' }}>
                    Sem dados por enquanto
                  </span> : <div>
                    <span className='d-block fs-4 py-2' style={{ fontSize: '10px' }}>{Number(ambassador.followers).toLocaleString()}</span>
                    <span className='d-block' style={{ fontSize: '10px' }}>Seguidores</span>
                  </div>
                  ,
                  <TagsList
                    tags={ambassador.tagsName}
                    ambassadorId={ambassador.ambassadorId}
                    canCreateTag={ambassador.approvalStatus}
                    handleGetAmbassadorTagList={handleGetAmbassadorTagList}
                  />,
                  <AmbassadorResultDashboard
                    name={ambassador.name}
                    ambassador={ambassador.ambassadorId}
                    approval={ambassador.approvalStatus}
                  />,
                  <div className="d-flex justify-content-center align-items-center w-100px">
                    {
                      ambassador.approvalStatus === undefined ?
                        <>
                          <ActionButton
                            type="approved"
                            className={'text-light mx-1'}
                            onClick={() => handleChangeStatusRequest(true, ambassador.id)}
                          >
                            <Check width={14} />
                          </ActionButton>

                          <ActionButton
                            type="reproved"
                            className={'text-light mx-1'}
                            onClick={() => handleChangeStatusRequest(false, ambassador.id)}
                          >
                            <XMark width={14} />
                          </ActionButton>
                        </>
                        : ambassador.approvalStatus === true ? <span className='approvedTxt'><FaCheck /> Aprovado</span> : <span className='declinedTxt'><FaTimes /> Reprovado</span>
                    }
                  </div>,
                  <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic" className='BsThreeDotsVertical' disabled={ambassador.approvalStatus === undefined && true}>
                      <BsThreeDotsVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {ambassador.approvalStatus === true && <Dropdown.Item eventKey="2" onClick={() => handleChangeStatusRequest(false, ambassador.id)}>Reprovar</Dropdown.Item>}
                      {ambassador.approvalStatus === false && <Dropdown.Item eventKey="1" onClick={() => handleChangeStatusRequest(true, ambassador.id)}>Aprovar</Dropdown.Item>}
                    </Dropdown.Menu>
                  </Dropdown>
                ],
                subValues: [
                  '',
                  <span className={`${styles.subValueText} text-nowrap`}><IoMdMail fill='#7E8299' /> {ambassador.email}</span>,
                  '',
                  '',
                  <InterestsList interests={ambassador.interestsName} />,
                  '',
                  ''
                ]
              }))
          }
          hasBatchActions={false}
          hasCollapsableRows
          titleErro='Você ainda não tem nenhum embaixador'
          messageErro='Que tal criar o primeiro desafio da sua marca ?'
        />
      </div>
    </section>
  )
}
