import * as Yup from "yup";
import { isValid, parse } from 'date-fns'

const millisecondsInADay = 86400000
const tomorrow = new Date(new Date().setHours(0, 0, 0, 0) + millisecondsInADay)

const formSchema = Yup.object().shape({
  title: Yup.string().min(3, "Tamanho mínimo de 3 caracteres").max(56, "Tamanho máximo 56 caracteres").required('Informe o título do desafio'),
  description: Yup.object().required('Informe a descrição do desafio'),
  dueDate: Yup.string()
    .test('valid-date', 'A data deve ser válida', (value) => {
      const date = parse(value, 'dd/MM/yyyy', new Date())
      return isValid(date)
    })
    .test('min', 'A data deve ser igual ou posterior o dia de hoje', (value) => {
      const date = parse(value, 'dd/MM/yyyy', new Date())
      return date >= tomorrow
    })
    .required('Informe a validade do desafio'),
  minimumSubtotal: Yup.string()
    .when("discountType", {
      is: (value) => value === "value",
      then: Yup.string().required("Infome o valor a ser pago"),
    }),
  discountPercentage: Yup.string()
    .when("discountType", {
      is: (value) => value === "percent",
      then: Yup.string().required("Informe a porcentagem do desconto"),
    }),
  discountMoney: Yup.string()
    .when("discountType", {
      is: (value) => value === "value",
      then: Yup.string().required("Infome o valor do desconto"),
    }),
  typecommission: Yup.string().notRequired(),
  commissionMoney: Yup.string()
    .when("typecommission", {
      is: (value) => value === "value",
      then: Yup.string().required("Infome o valor mínimo para o pedido"),
    }),
  discountType: Yup.string().required("").nullable(),
  externalId: Yup.string().notRequired(),
  image_cover: Yup.mixed().notRequired()
});


const editFormSchema = Yup.object().shape({
  description: Yup.object().required('Informe a descrição do desafio'),
  image_cover: Yup.mixed().notRequired()
});

export { formSchema, editFormSchema };
