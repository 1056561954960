import { OverlayTrigger, Popover } from "react-bootstrap";

import { ReactComponent as Instagram } from "../../../../assets/svg/socials/instagram_alt.svg";
import { ReactComponent as Facebook } from "../../../../assets/svg/socials/facebook_alt.svg";
import { ReactComponent as TikTok } from "../../../../assets/svg/socials/tiktok_alt.svg";
import { ReactComponent as Twitter } from "../../../../assets/svg/socials/twitter_alt.svg";
import { ReactComponent as Youtube } from "../../../../assets/svg/socials/youtube_alt.svg";

type Props = {
  medias: {
    instagram?: string;
    facebook?: string;
    tiktok?: string;
    twitter?: string;
    youtube?: string;
  };
}

export const SocialMediaList = ({ medias }: Props) => {
  const renderTooltip = (props) => (
    <Popover id="button-tooltip" {...props}>
      <div className='p-4 d-block fw-normal'>
        {props}
      </div>
    </Popover>
  );

  return (
    <div className='d-flex justify-content-center align-items-center mt-5 mb-5 min-h-45px'>
      <div className='mx-1'>
        {
          medias?.instagram ?
            <a href={`https://www.instagram.com/${medias?.instagram.replace('@', '')}`} rel="noreferrer" target="_blank">
              <OverlayTrigger
                trigger={["hover", "hover"]}
                placement='top'
                overlay={renderTooltip(medias?.instagram)}
              >
                <Instagram
                  fill={medias?.instagram ? "#7E8299" : "#7E829933"}
                  className={medias?.instagram && "cursor-pointer"}
                />
              </OverlayTrigger>
            </a>
            :
            <Instagram
              fill={medias?.instagram ? "#7E8299" : "#7E829933"}
              className={medias?.instagram && "cursor-pointer"}
            />
        }
      </div>

      <div className='mx-1'>
        {
          medias?.facebook ?
            <a href={`https://pt-br.facebook.com/${medias?.facebook.replace('@', '')}`} rel="noreferrer" target="_blank">
              <OverlayTrigger
                trigger={["hover", "hover"]}
                placement='top'
                overlay={renderTooltip(medias?.facebook)}
              >
                <Facebook
                  fill={medias?.facebook ? "#7E8299" : "#7E829933"}
                  className={medias?.facebook && "cursor-pointer"}
                />
              </OverlayTrigger>
            </a>
            :
            <Facebook
              fill={medias?.facebook ? "#7E8299" : "#7E829933"}
              className={medias?.facebook && "cursor-pointer"}
            />
        }
      </div>

      <div className='mx-1'>
        {
          medias?.tiktok ?
            <a href={`https://www.tiktok.com/@${medias?.tiktok.replace('@', '')}`} rel="noreferrer" target="_blank">
              <OverlayTrigger
                trigger={["hover", "hover"]}
                placement='top'
                overlay={renderTooltip(medias?.tiktok)}
              >
                <TikTok
                  fill={medias?.tiktok ? "#7E8299" : "#7E829933"}
                  className={medias?.tiktok && "cursor-pointer"}
                />
              </OverlayTrigger>
            </a>
            :
            <TikTok
              fill={medias?.tiktok ? "#7E8299" : "#7E829933"}
              className={medias?.tiktok && "cursor-pointer"}
            />
        }
      </div>

      <div className='mx-1'>
        {
          medias?.twitter ?
            <a href={`https://twitter.com/${medias?.twitter.replace('@', '')}`} rel="noreferrer" target="_blank">
              <OverlayTrigger
                trigger={["hover", "hover"]}
                placement='top'
                overlay={renderTooltip(medias?.twitter)}
              >
                <Twitter
                  fill={medias?.twitter ? "#7E8299" : "#7E829933"}
                  className={medias?.twitter && "cursor-pointer"}
                />
              </OverlayTrigger>
            </a>
            :
            <Twitter
              fill={medias?.twitter ? "#7E8299" : "#7E829933"}
              className={medias?.twitter && "cursor-pointer"}
            />
        }
      </div>

      <div className='mx-1'>
        {
          medias?.youtube ?
            <a href={`https://www.youtube.com/@${medias?.youtube.replace('@', '')}`} rel="noreferrer" target="_blank">
              <OverlayTrigger
                trigger={["hover", "hover"]}
                placement='top'
                overlay={renderTooltip(medias?.youtube)}
              >
                <Youtube
                  fill={medias?.youtube ? "#7E8299" : "#7E829933"}
                  className={medias?.youtube && "cursor-pointer"}
                />
              </OverlayTrigger>
            </a>
            :
            <Youtube
              fill={medias?.youtube ? "#7E8299" : "#7E829933"}
              className={medias?.youtube && "cursor-pointer"}
            />
        }
      </div>
    </div>
  )
}