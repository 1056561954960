/* eslint-disable import/no-anonymous-default-export */

import * as Yup from "yup";
export default  [
  Yup.object({
    category: Yup.string()
    .required("Selecione a categoria da sua marca")
    .nullable(),
  }),
  Yup.object({
    image_cover: Yup.string()
    .required("Carregue uma foto para sua marca")
    .nullable(),
  }),
  Yup.object({
    aboutMe: Yup.string()
    .min(3, "Minimo de 3 caracteres")
    .max(420, "Maximo 420 caracteres")
    .required("Por favor, descreva brevemente sobre sua marca.")
    .nullable(),
  }),
  /*Yup.object({
    acceptRule: Yup.string().required().nullable(),
  }),*/
  Yup.object({
    socials: Yup.object({
      facebook: Yup.string()
        .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Infome a url do facebook')
        .notRequired(),
      instagram: Yup.string()
        .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Infome a url do instagram')
        .notRequired(),
      tiktok: Yup.string()
        .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Infome a url do tiktok')
        .notRequired(),
      twitter: Yup.string()
        .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Infome a url do twitter')
        .notRequired(),
      youtube: Yup.string()
        .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Infome a url do youtube')
        .notRequired()
    }),
  }),
]

