import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { Profile } from '../pages/Profile/Profile';
import Challenge from '../pages/Challenge/Challenge';
import Dashboard from '../pages/Dashboard/Dashboard';
import AddFunds from '../pages/Add-Funds/Add-Funds';
import Integration from '../pages/Integration/Integration';
import IntegrationEdit from '../pages/IntegrationEdit/IntegrationEdit';
import IntegrationHash from '../pages/IntegrationHash/IntegrationHash';
import NewChallenge from '../pages/Challenge/Sales/pages/create';
import WithdrawFunds from '../pages/Withdraw-Funds/Withdraw-Funds';
import ViewChallenge from '../pages/Challenge/Sales/pages/view';
import Influencer from '../pages/Influencer/Influencer';
import Legate from '../pages/Settings/components/Legate/Legate';
import Connectors from '../pages/Settings/components/Connectors/Connectors';
import FinancialMoney from '../pages/Settings/components/FinancialMoney/FinancialMoney';
import EditChallenge from 'app/pages/Challenge/Sales/pages/edit';
import CreateEngagementChallenge from 'app/pages/Challenge/Engagement';
import Sales from 'app/pages/Sales';
import EditEngagementChallenge from 'app/pages/Challenge/Engagement/edit';



const PrivateRoutes = () => (
  <Routes>
    <Route element={<MasterLayout />}>
      <Route path='auth/*' element={<Navigate to='/dashboard' />} />

      <Route path='dashboard' element={<Dashboard />} />

      <Route path='dashboard/sales' element={<Sales />} />
      <Route path='dashboard/financial/add-funds' element={<AddFunds />} />
      <Route path='dashboard/financial/withdraw-funds' element={<WithdrawFunds />} />

      <Route path='dashboard/chart-request' element={<Influencer />} />
      <Route path='dashboard/profile' element={<Profile />} />

      <Route path='dashboard/integration' element={<Integration />} />
      <Route path='dashboard/integration/:id' element={<IntegrationHash />} />
      <Route path='dashboard/integration/edit/:id' element={<IntegrationEdit />} />

      <Route path='dashboard/challenge' element={<Challenge />} />
      <Route path='dashboard/challenge/engagement/edit/:id' element={<EditEngagementChallenge />} />
      <Route path='dashboard/challenge/sale/edit/:id' element={<EditChallenge />} />

      <Route path='dashboard/challenge/new' element={<NewChallenge />} />
      <Route path='dashboard/challenge/:type/view/:id' element={<ViewChallenge />} />

      <Route path='dashboard/challenge/engagement/create' element={<CreateEngagementChallenge />} />

      <Route path='dashboard/settings/chart-request' element={<Legate />} />
      <Route path='dashboard/settings/integration' element={<Connectors />} />
      <Route path='dashboard/settings/financial' element={< FinancialMoney />} />

      <Route path='*' element={<Navigate to='/error/404' />} />
    </Route>
  </Routes>
)

export { PrivateRoutes }
