/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getAmbassadors, getBalance, getConnectores, getListChallenge, getUser, login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import Pages from '../../../components/Page/Pages'
import axios from 'axios'
import jwt_decode from "jwt-decode";
import { TextField } from 'app/components'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Informe o seu e-mail no formato email@email.com.br')
    .min(3, 'minimo 3 caracteres')
    .max(50, 'Maximos 50 caracteres')
    .required('Informe seu e-mail'),
  password: Yup.string()
    .min(8, 'minimo 8 caracteres')
    .max(50, 'Maximos 50 caracteres')
    .required('Seu e-mail e/ou senha estão incorretos. Confira e tente de novo, por favor.'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState<boolean>(false)
  const { saveAuth } = useAuth()

  const intl = useIntl()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const response: any = await login(values.email, values.password)
        const token = response.data.accessToken
        const res = await axios.post('catalyst/verify-token', { accessToken: response.data.accessToken })

        localStorage.setItem('accessPayload', JSON.stringify(res.data))

        const user: any = jwt_decode(token);
        const requestUser = await getUser(user?.sub);
        const conectorsList = await getConnectores(token);
        const ambassadors = await getAmbassadors({ token: token, brandId: user?.sub })
        const balence = await getBalance(token)
        const challenge = await getListChallenge(token)

        const list_chart = ambassadors.data.totalNewRequests
        const list = conectorsList.data.data
        const value = balence.data.data.total

        const isActive = (list: any) => list.connected === true
        const reslist = list.find(isActive)

        const auth = {
          api_token: token,
          name: user?.name,
          email: user?.email,
          hasConnector: reslist === undefined ? false : reslist.connected,
          id: user?.sub,
          brandId: requestUser?.data?.fk_id_brand,
          chart_request: list_chart,
          balence: value,
          challenge: challenge.data.stats.totalChallenges,
          shopify: list[0].connected,
          tray: list.filter(e => e.title === 'tray')[0].connected
        }

        saveAuth(auth)

        if (res.status === 200) {
          window.analytics.identify(res.data.email, {
            name: res.data.name,
            email: res.data.email
          });
          window.analytics.track('Logged');
          window.location.href = '/'
        }
      } catch (error) {
        saveAuth(undefined)
        setStatus('E-mail ou senha incorretos. Confira e tente de novo, por favor.')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <Pages type='Bloco'>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='mb-10 '>
          <h1 className='text-black mb-3'>Gerencie a comunidade da sua marca</h1>
          <div className='text-gray-600 fs-5 font-roboto'>
            Se ainda não tiver um cadastro, você pode {" "}
            <Link to='/auth/registration' className='link-info text-decoration-underline'>
              criar sua conta aqui.
            </Link>
          </div>
        </div>
        {/* 
          TODO - O que esse status está querendo fazer? 
          Ele mostra um disclaimer de erro mesmo quando o status é 200
         */}
        {formik.status && (
          <div className='alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}


        <div className='fv-row mb-10'>
          <TextField
            label='E-mail'
            autoComplete='on'
            placeholder=''
            touched={formik.touched.email}
            error={formik.errors.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            {...formik.getFieldProps('email')}
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <TextField
            label='Senha'
            type='password'
            autoComplete='on'
            placeholder=''
            touched={formik.touched.password}
            error={formik.errors.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            {...formik.getFieldProps('password')}
          />
        </div>

        <div>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-info'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Acessar conta</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Acessando...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to={'/auth/forgot-password'}>
            <span className='btn text-info text-decoration-underline'>{intl.formatMessage({ id: 'FORGOTPASS' })}</span>
          </Link>
        </div>


      </form>
    </Pages >
  )
}


